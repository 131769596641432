"use client";

import DashboardCard from "@/app/(homepage)/dashboard/dashboardCard";
import { generateShortWeeklySpecials } from "@/app/(homepage)/weekly-specials/weeklySpecialsCountdown";
import type { DashboardData } from "@/fetch/getDashboardData";
import Link from "next/link";
import Countdown from "react-countdown";
import style from "./firstCard.module.scss";

export default function FirstCard({ dashboardData }: FirstCardProps) {
  if (dashboardData?.customerOrderNumber) {
    return (
      <DashboardCard
        id={style.reorderCard}
        title="Reorder"
        bodyClassName={`d-flex justify-content-around flex-column pt-3 pb-3 ${style.reorderCard}`}
      >
        <div className={`d-flex ${style.cardItemWithImage}`}>
          <div className={style.icon}>
            <img src="/media/shared/common/icons/icon-reorder.svg" alt="Reorder" />
          </div>
          <div>
            <span className="d-block medium">Reorder frequently purchased</span>
            <Link href="/reorder">View items &raquo;</Link>
          </div>
        </div>
        {dashboardData.itemsToRestockCount > 0 ? (
          <div className={`d-flex ${style.cardItemWithImage}`}>
            <div className={style.icon}>
              <div className={style.circleBadge}>{dashboardData.itemsToRestockCount}</div>
            </div>
            <div>
              <span className="d-block medium">Items to restock soon!</span>
              <a href="/reorder?sortBy=timeToPurchasePrediction">View items &raquo;</a>
            </div>
          </div>
        ) : (
          <div className={`top-sellers d-flex ${style.cardItemWithImage}`}>
            <div className={style.icon}>
              <img src="/media/shared/common/icons/icon-verified-check.svg" alt="Top Sellers" />
            </div>
            <div>
              <span className="d-block medium">Top Sellers</span>
              <Link href="/top-sellers">Shop now &raquo;</Link>
            </div>
          </div>
        )}
      </DashboardCard>
    );
  } else {
    return (
      <DashboardCard
        title="Start Saving"
        id={style["need-help-getting-started"]}
        bodyClassName={`d-flex justify-content-between flex-column ${style.weeklySpecials}`}
      >
        <div className={`top-sellers d-flex ${style.cardItemWithImage}`}>
          <div className={style.icon}>
            <img src="/media/shared/common/icons/icon-verified-check.svg" alt="Top Sellers" />
          </div>
          <div>
            <span className="d-block medium">Top Sellers</span>
            <Link href="/top-sellers">Shop now &raquo;</Link>
          </div>
        </div>
        <div className={`weekly-specials d-flex ${style.cardItemWithImage}`}>
          <div className={style.icon}>
            <img src="/media/shared/common/icons/icon-tag.svg" alt="Weekly Specials" />
          </div>
          <div>
            <span className="d-block medium">
              Weekly Specials{" "}
              {dashboardData?.promotionEndTime && (
                <Countdown
                  date={dashboardData?.promotionEndTime}
                  renderer={(props) => {
                    const message = generateShortWeeklySpecials(props.days, props.hours, props.minutes, props.seconds);
                    return <span className="ml-1 text-secondary regular">{message} left</span>;
                  }}
                />
              )}
            </span>
            <Link href="/weekly-specials">View items &raquo;</Link>
          </div>
        </div>
        <div className={style.viewCategories}>
          <Link href="/ec/dental-supplies">View all Categories »</Link>
        </div>
      </DashboardCard>
    );
  }
}

export type FirstCardProps = {
  dashboardData: DashboardData | null;
};
