import { AppState } from "@/store/store";

export function isRootState(state: unknown): state is AppState {
  if (state && typeof state === "object") {
    return (
      "shoppingCartSlice" in state &&
      "searchSlice" in state &&
      "recommendationsSlice" in state &&
      "compareProductsSlice" in state &&
      "checkoutSlice" in state &&
      "addToShoppingListSlice" in state &&
      "confirmPasswordChangeSlice" in state
    );
  }
  return false;
}
