"use client";

import RecommendedForYou from "@/app/ec/product/recommendations/wrappers/recommendedForYou";
import style from "./homePageProductRecommendations.module.scss";
export default function HomePageProductRecommendations() {
  return (
    <div className={style.container}>
      <section id="recommended-for-you" className={style.containsRecommendations}>
        <RecommendedForYou
          fallbackModel="trending-items"
          modelParams={{
            fallbackParameters: {
              query: "",
              filters: `availability:Y`,
            },
            queryParameters: {
              filters: `availability:Y`,
            },
          }}
          title="Recommended for you"
          orderBy="priority"
          displayGrid={true}
        />
      </section>
    </div>
  );
}
