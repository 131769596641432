"use client";

import { DashboardData } from "@/fetch/getDashboardData";
import { useGetShoppingCartQuery } from "@/store/shoppingCartApi";
import Link from "next/link";
import { NumericFormat } from "react-number-format";
import style from "./customerHistoryBlock.module.scss";

export default function CustomerHistoryBlock({ dashboardData, className = "" }: CustomerHistoryBlockProps) {
  const { data: shoppingCart, isLoading: shoppingCartIsLoading } = useGetShoppingCartQuery();
  if (
    dashboardData &&
    (dashboardData.numberOfRecentOrders || dashboardData.spentThisMonth || dashboardData.savingsLast12Months)
  ) {
    return (
      <div className={className}>
        <div id="recent-orders" className={style.wrapper}>
          <div className={style.title}>Recent orders</div>
          <div className={`${style.info} d-flex align-items-center`}>
            <span className="me-1">{dashboardData.numberOfRecentOrders}</span>
            <a href="/account/orders" className="text-white">
              View orders
            </a>
          </div>
        </div>
        <div id="spent-this-month" className={`${style.wrapper} ${style.spentThisMonth}`}>
          <div className={style.title}>Spent this month</div>
          <div className={`${style.info} d-flex align-items-center`}>
            <span className="me-1">
              <NumericFormat
                prefix="$"
                displayType="text"
                value={Math.floor(dashboardData.spentThisMonth)}
                thousandSeparator={true}
              />
            </span>
          </div>
        </div>
        <div id="saved-last-12-months" className={`${style.wrapper} ${style.savedLast12Months}`}>
          <div className={style.title}>Saved last 12 months</div>
          <div className={`${style.info} d-flex align-items-center`}>
            <span className="me-1">
              <NumericFormat
                prefix="$"
                displayType="text"
                value={Math.floor(dashboardData.savingsLast12Months)}
                thousandSeparator={true}
              />
            </span>
          </div>
        </div>
        <div id="saved-year-to-date" className={style.wrapper}>
          <div className={style.title}>YTD Savings</div>
          <div className={`${style.info} d-flex align-items-center`}>
            <span className="me-1">
              <NumericFormat
                prefix="$"
                displayType="text"
                value={Math.floor(dashboardData.savingsYearToDate)}
                thousandSeparator={true}
              />
            </span>
          </div>
        </div>
      </div>
    );
  } else if (shoppingCart && shoppingCart.totalQuantity > 0) {
    return (
      <div className={className}>
        <div className={`shopping-cart-message ${style.cartMessage}`}>
          <div>You have {shoppingCart.totalQuantity} items in your cart</div>
          <a href="/shopping-cart" className="text-white light">
            View Cart
          </a>
        </div>
      </div>
    );
  } else if (shoppingCartIsLoading === true) {
    return (
      <div className={className}>
        <div id={style.loading}></div>
      </div>
    );
  }
  return (
    <div className={className}>
      <div className={`shopping-cart-message ${style.cartMessage}`}>
        <div>Your Shopping Cart is empty</div>
        <Link href="/top-sellers" className="text-white light">
          Start shopping now!
        </Link>
      </div>
    </div>
  );
}

export type CustomerHistoryBlockProps = {
  dashboardData: DashboardData | null;
  className?: string;
};
