"use client";

import { getBannerLink } from "@/utils/topsortUtil";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import style from "./topsortBanner.module.scss";

const TopsortBanner: FunctionComponent<TopSortBannerProps> = ({ bannerInfo }) => {
  console.log("bannerInfo: ", bannerInfo);
  const [isBannerOffscreen, setIsBannerOffscreen] = useState<boolean>(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const currentref = elementRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerOffscreen(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      },
    );

    if (currentref) {
      observer.observe(currentref);
    }

    return () => {
      if (currentref) {
        observer.unobserve(currentref);
      }
    };
  }, []);

  return (
    <div id="topsort-banner" ref={elementRef} className={style.topSortBanner}>
      <Carousel className={style.topSortCarousel} interval={isBannerOffscreen ? null : 7000} controls={false}>
        {bannerInfo.map((banner, index) => {
          return (
            <Carousel.Item key={index}>
              <div className={style.bannerItem}>
                <a
                  key={index}
                  href={getBannerLink(banner)}
                  data-ts-product={banner.type === "product" ? banner.id : ""}
                  data-ts-resolved-bid={banner.resolvedBidId}
                  data-ts-clickable={true}
                >
                  <picture
                    {...(banner.type === "product" ? { "data-ts-product": banner.id } : {})}
                    {...(banner.resolvedBidId
                      ? { "data-ts-resolved-bid": banner.resolvedBidId, "data-ts-clickable": true }
                      : {})}
                  >
                    <source media="(max-width: 700px)" srcSet={banner.asset[1]?.url} width="100%" height={650} />
                    <source media="(min-width: 701px)" srcSet={banner.asset[0]?.url} width="100%" height={400} />
                    <img
                      className={style.bannerImage}
                      alt=""
                      src={banner.asset[0]?.url} // Fallback image
                      width="100%"
                      height="400"
                    />
                  </picture>
                </a>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default TopsortBanner;

export type TopSortBannerProps = {
  bannerInfo: Winner[];
};
