import fixImageURI from "@/app/ec/product/fixImageURI";
import { baseApi } from "@/store/baseApi";
import dayjs from "dayjs";

export const shoppingCartApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ["SHOPPING_CART"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getShoppingCart: builder.query<ShoppingCartState, void>({
        query: () => "neo/shopping-cart/get",
        providesTags: () => ["SHOPPING_CART"],
        transformResponse: ({
          payload: shoppingCart,
        }: Melatonin.BasicRestfulResponse<ShoppingCartPayloadFromServer>) => {
          const shoppingCartProducts = shoppingCart.vendorOrders.flatMap((vo) => {
            return vo.products;
          });
          return {
            subTotal: shoppingCart.subTotal,
            totalQuantity: shoppingCartProducts
              .filter((p) => !p.noLongerForSale)
              .map((p) => p.quantity)
              .reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
              }, 0),
            isMerged: shoppingCart.isMerged,
            standardShipping: shoppingCart.standardShipping,
            heavyShipping: shoppingCart.heavyShipping,
            retailTotal: shoppingCart.retailTotal,
            savingsOverRetail: shoppingCart.savingsOverRetail,
            grandTotal: shoppingCart.subTotal + shoppingCart.standardShipping + shoppingCart.heavyShipping,

            shoppingCartHash: shoppingCartProducts
              .map((p) => {
                return (
                  p.mpId.toString() + p.vendorProductId.toString() + p.quantity.toString() + p.totalInventory.toString()
                );
              })
              .join(""),
            vendorOrders: shoppingCart.vendorOrders.map((vendorOrder) => {
              return {
                ...vendorOrder,
                products: vendorOrder.products.map((product) => {
                  const returnableProduct: ShoppingCartVendorOrderProduct = {
                    ...product,
                    largeImagePath: fixImageURI(product.largeImagePath),
                    smallImagePath: fixImageURI(product.smallImagePath),
                  };
                  if (returnableProduct.nextAutoOrderDate) {
                    returnableProduct.nextAutoOrderDate = dayjs(returnableProduct.nextAutoOrderDate).format(
                      "MM/DD/YYYY",
                    );
                  }
                  return returnableProduct;
                }),
              };
            }),
            estimatedTax: 0,
            shoppingCartErrors: [],
            shoppingCartSmartMessages: [],
          };
        },
      }),

      modifyShoppingCart: builder.mutation<ModifyShoppingCartResponse, ModifyShoppingCartProps>({
        query: ({ productsToModify, incrementQuantity }) => ({
          url: "neo/shopping-cart/modify" + (incrementQuantity ? "?incrementQuantity=true" : ""),
          method: "POST",
          body: productsToModify,
        }),

        invalidatesTags: ["SHOPPING_CART"],
      }),
    }),
  });

export const {
  useGetShoppingCartQuery,
  useLazyGetShoppingCartQuery,
  useModifyShoppingCartMutation,
  util: { getRunningQueriesThunk },
} = shoppingCartApi;
export const { getShoppingCart, modifyShoppingCart } = shoppingCartApi.endpoints;

export type ModifyShoppingCartProps = {
  productsToModify: Array<ModifyCartProduct>;
  incrementQuantity?: boolean;
};
