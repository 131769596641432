import { ProductHit } from "@/components/products-tile/product";
import { defaultIndex } from "@/configs/constants";
import getCategoryLeaf from "@/utils/analytics/getCategoryLeaf";
import { AnalyticsReportProductV2 } from "@/utils/analyticsEventSender";
import fixURI from "@/utils/fixURI";
import getCategoryLeafFromString from "@/utils/getCategoryLeafFromString";
import axios from "axios";
import Big from "big.js";

/**
 * Will examine the products provided and request more details if needed.
 * @param products
 * @returns
 */
export default async function getProductDetailsForReport(
  products: AnalyticsReportProductV2[],
): Promise<AnalyticsReportProductV2[]> {
  if (products.length === 0) {
    return products;
  }
  console.log(`GTM | More info required for the following mpIds: ${products.join(", ")}`);

  const finalSearchQuery = {
    filters: "objectID:" + products.map((p) => p.item_id).join(" OR objectID:"),
    hitsPerPage: 1000,
  };
  const { data: searchResult } = await axios.post<AlgoliaSearchResponse>(
    `https://${process.env.NEXT_PUBLIC_AlGOLIA_APP_ID}-dsn.algolia.net/1/indexes/${defaultIndex}/query`,
    finalSearchQuery,
    {
      headers: {
        "X-Algolia-Application-Id": process.env.NEXT_PUBLIC_AlGOLIA_APP_ID,
        "X-Algolia-API-Key": process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
      },
    },
  );
  if (searchResult) {
    products = products.map((p): AnalyticsReportProductV2 => {
      const prodDetails = searchResult.hits?.find((pd) => pd.mp_id.toString() === p.item_id);
      if (!prodDetails) {
        // this should not occur except in extraordinary circumstances
        return p;
      }
      const productReport: AnalyticsReportProductV2 = {
        ...p,
        item_name: prodDetails.title,
        item_brand: prodDetails.brand,
        price: p.price ? p.price : prodDetails.price,
        item_category: prodDetails.category.lvl1 ? getCategoryLeafFromString(prodDetails.category.lvl1) : undefined,
        item_category2: prodDetails.category.lvl2 ? getCategoryLeafFromString(prodDetails.category.lvl2) : undefined,
        item_category3: prodDetails.category.lvl3 ? getCategoryLeafFromString(prodDetails.category.lvl3) : undefined,
        item_category4: prodDetails.category.lvl4 ? getCategoryLeafFromString(prodDetails.category.lvl4) : undefined,
        item_specific_category: getCategoryLeaf(prodDetails),
        item_absolute_url: `${process.env.NEXT_PUBLIC_BASE_URL}${fixURI(prodDetails.link)}`,
        item_absolute_image: `${process.env.NEXT_PUBLIC_BASE_URL}${fixURI(prodDetails.image_link)}`,
        item_subtotal: new Big(p.price ? p.price : prodDetails.price).times(p.quantity).toNumber(),
      };

      return productReport;
    });
  }
  // The following auto-cleans any undefined attributes
  products = products.map((p) => {
    (Object.keys(p) as Array<keyof AnalyticsReportProductV2>).forEach(
      (key) => typeof p[key] === "undefined" && delete p[key],
    );
    return p;
  });
  return products;
}

type AlgoliaSearchResponse = {
  hits: ProductHit[];
};
