import { ProductHit } from "@/components/products-tile/product";
import getCategoryLeafFromString from "@/utils/getCategoryLeafFromString";

export default function getCategoryLeaf(product: ProductHit) {
  if (product.category.lvl4) {
    return getCategoryLeafFromString(product.category.lvl4);
  }
  if (product.category.lvl3) {
    return getCategoryLeafFromString(product.category.lvl3);
  }
  if (product.category.lvl2) {
    return getCategoryLeafFromString(product.category.lvl2);
  }
  if (product.category.lvl1) {
    return getCategoryLeafFromString(product.category.lvl1);
  }
}
