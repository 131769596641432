"use client";
import { Swiper as SwiperInterface } from "swiper";
import { Grid, Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { selectedTopCategories } from "@/app/(homepage)/homepage-info.json";
import { useRef, useState } from "react";
import style from "./topCategories.module.scss";
const topCategories = selectedTopCategories["Net32"];

export default function TopCategories({}: TopCategoriesProps) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | null>(null);

  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  return (
    <div id={style.topSellingCategories} className="contains-recommendations">
      <div className={style.sectionHeader}>Top Selling Categories</div>
      <div className={`d-none d-md-flex align-items-center`}>
        <div className={`swiper-arrow ${style.swiperArrow} ${style.prev}`} ref={navigationPrevRef}></div>
        <Swiper
          className={`flex-grow-1 ${style.topCategorySlider}`}
          modules={[Navigation, Virtual]}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpointsBase="container"
          breakpoints={{
            350: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            630: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            800: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
            1050: {
              slidesPerView: 6,
              slidesPerGroup: 6,
            },
          }}
          loop={false}
          direction="horizontal"
          mousewheel={false}
        >
          {topCategories.map((topCategory: TopCategory, index) => {
            return (
              <SwiperSlide key={index} className="">
                <div key={topCategory.parentCategoryCSSID} className={style.topCategoryCard}>
                  <a
                    id={style[`top-category-${topCategory.parentCategoryCSSID}`]}
                    className={`${style["top-selling-category-image"]} ${style["text-dark"]}`}
                    href={topCategory.parentCategoryURL}
                  ></a>
                  <a
                    className={`${style["font-weight-bold"]} ${style["category-name"]} ${style["text-dark"]} sm`}
                    href={topCategory.parentCategoryURL}
                  >
                    {topCategory.parentCategoryName}
                  </a>
                  {topCategory.childCategories &&
                    topCategory.childCategories.map((childCategory, index) => {
                      return (
                        <a
                          key={index}
                          className={`${style["sub-category-link"]} ${style["category-name"]} ${style["text-dark"]} sm`}
                          href={childCategory.childCategoryURL}
                        >
                          {childCategory.childCategoryName}
                        </a>
                      );
                    })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={`swiper-arrow ${style.swiperArrow} ${style.next}`} ref={navigationNextRef}></div>
      </div>

      <div className={`d-block d-md-none `}>
        <Swiper
          className={`${style.topCategorySlider} ${style.mobile}`}
          autoHeight={true}
          modules={[Grid]}
          slidesPerView={2}
          grid={{ rows: 3, fill: "row" }}
          simulateTouch={false}
          direction="horizontal"
          breakpoints={{
            576: {
              slidesPerView: 3,
              grid: { rows: 2 },
            },
          }}
        >
          {topCategories.map((topCategory: TopCategory, index) => {
            return (
              <SwiperSlide key={index} className="">
                <div key={topCategory.parentCategoryCSSID} className={style.topCategoryCard}>
                  <a
                    id={style[`top-category-${topCategory.parentCategoryCSSID}`]}
                    className={`${style["top-selling-category-image"]} ${style["text-dark"]}`}
                    href={topCategory.parentCategoryURL}
                  ></a>
                  <a
                    className={`${style["font-weight-bold"]} ${style["text-dark"]} sm`}
                    href={topCategory.parentCategoryURL}
                  >
                    {topCategory.parentCategoryName}
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
export type TopCategoriesProps = {};

type TopCategory = {
  parentCategoryName: string;
  parentCategoryCSSID: string;
  parentCategoryURL: string;
  childCategories?: {
    childCategoryName: string;
    childCategoryURL: string;
  }[];
};
