import style from "./dashboardCard.module.scss";

export default function DashboardCard({ id, title, className, bodyClassName, children }: DashboardCardProps) {
  return (
    <div id={id} className={`${style.card} ${className}`}>
      <div className={style.title}>{title}</div>
      <div className={`${style.content} ${bodyClassName}`}>{children}</div>
    </div>
  );
}

export type DashboardCardProps = {
  id: string;
  className?: string;
  bodyClassName?: string;
  title: string;
  children?: React.ReactNode;
};
