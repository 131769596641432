import { baseApi } from "@/store/baseApi";

type GetRecentlyViewedItemsProps = {
  mpIds: string[];
};

export const recentyViewedApi = baseApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getRecentlyViewedItems: builder.query<RecentlyViewedProduct[], GetRecentlyViewedItemsProps>({
      queryFn: async ({ mpIds }) => {
        if (!mpIds.length) {
          return { data: [] };
        }
        try {
          const response = await fetch(`/rest/neo/search/recentlyViewed`, {
            method: "POST",
            body: JSON.stringify(mpIds),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          const data = await response.json();

          return { data };
        } catch {
          return { data: [] };
        }
      },
    }),
  }),
});

export const { useGetRecentlyViewedItemsQuery } = recentyViewedApi;
export const { getRecentlyViewedItems } = recentyViewedApi.endpoints;

export type RecentlyViewedProduct = {
  mpId: number;
  title: string;
  generalRating?: number;
  numberOfGeneralRatings?: number;
  brand: string;
  link: string;
  imageLink: string;
  bestPrice: {
    vendorProductId: number;
    manufacturerProductId: number;
    minQty: number;
    unitPrice: number;
    retailPrice: number;
    quantity: number;
    inStockSw: boolean;
    /** VENDOR name, not product name. */
    name: string;
    averageShipTime: number;
    shippingCost: number;
    heavyShippingCost: number;
    vdrNumberOfGeneralRatings: number;
    vdrGeneralAverageRatingSum: number;
    backOrdered: boolean;
    inventory: number;
    fulfillmentPolicyId: number;
    productTag: string;
    mpName: string;
    brandName: string;
    url: string;
    authorizerDistributorEnabled?: boolean;
    authorizedImagePath?: string;
    priceEffectiveDate?: string;
    priceEndingDate?: string;
    lastPurchaseDate?: string;
    isLowestTotalPrice?: boolean;
    vendorId: number;
    vendorName?: string;
    vendorRegion?: string;
    /** If this becomes relevant, determine type. */
    productAverageHandlingTime?: unknown;
    /** If this becomes relevant, determine type. */
    promisedHandlingTime?: unknown;
    /** If this becomes relevant, determine type. */
    standardShippingStatus?: unknown;
    /** If this becomes relevant, determine type. */
    heavyShippingStatus?: unknown;
    subTotal: number;
    totalCost: number;
    /** If this becomes relevant, determine type. */
    shippingTime?: unknown;
  };
};
