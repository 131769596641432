"use client";

import Link from "next/link";
import { useMemo, useRef, useState } from "react";
import { Swiper as SwiperInterface } from "swiper";
import { FreeMode, Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import style from "./quickSearchSwiper.oldStyle.module.scss";

const QuickSearchSwiper = ({ searchData }: { searchData: SearchData | null }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | null>(null);

  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  const shouldShowSwiperSideNavigationArrows = useMemo((): boolean => {
    if (swiperInstance) {
      return swiperInstance.allowSlidePrev === true || swiperInstance.allowSlideNext === true;
    }
    return false;
  }, [swiperInstance?.allowSlidePrev, swiperInstance?.allowSlideNext]);

  return (
    <div id={style["header-secondary-swiper"]} className={style.quickSearchContainer}>
      <div
        className={`${shouldShowSwiperSideNavigationArrows !== true && "d-none"} ${style["swiper-arrow"]} ${style["swiper-arrow-style"]} ${style["swiper-arrow-prev"]} ${style["swiper-arrow-primary"]}`}
        ref={navigationPrevRef}
      ></div>

      <Swiper
        modules={[FreeMode, Mousewheel, Navigation]}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        spaceBetween={5}
        slidesPerView={"auto"}
        loop={false}
        freeMode={{
          enabled: true,
          sticky: true,
        }}
        direction="horizontal"
        mousewheel={false}
      >
        <SwiperSlide className={style.swiperElement}>
          <div className={style.quickSearchSVG}></div>
        </SwiperSlide>

        {searchData?.searches
          .filter((item) => item.search && item.search.trim() !== "")
          .map((item, index) => (
            <SwiperSlide key={index} className={style.swiperElement}>
              <Link
                prefetch={false}
                href={`/search?net32_products%5Bquery%5D=${encodeURIComponent(item.search)}`}
                className={style.quickSearchLink}
              >
                {item.search}
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>

      <div
        className={`${shouldShowSwiperSideNavigationArrows !== true && "d-none"} ${style["swiper-arrow"]} ${style["swiper-arrow-style"]} ${style["swiper-arrow-next"]} ${style["swiper-arrow-primary"]}`}
        ref={navigationNextRef}
      ></div>
    </div>
  );
};

export default QuickSearchSwiper;
