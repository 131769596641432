"use client";

import { Carousel } from "react-bootstrap";

import testimonialsAndTopCategories from "../homepage-info.json";

import Link from "next/link";
import style from "./testimonials.oldStyle.module.scss";

export default function Testimonials() {
  const testimonials: Testimonial[] = testimonialsAndTopCategories.testimonials.Net32.map((t) => {
    return { ...t, testimonialText: t.testimonialText.replace("\n\n", "\n") };
  });

  return (
    <aside id={style["testimonials"]} className="p-2">
      <Carousel controls={false}>
        {testimonials.map((testimonial, index) => {
          return (
            <Carousel.Item key={index}>
              <div>
                <p>&ldquo;{testimonial.testimonialText}&rdquo;</p>
                <strong className="d-block">{testimonial.name}</strong>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className={`${style["read-more-container"]} d-flex justify-content-center align-items-center`}>
        <Link href="/about-us/reviews" className={style["read-more-button"]}>
          Read More &raquo;
        </Link>
      </div>
    </aside>
  );
}

type Testimonial = {
  name: string;
  /** @deprecated */
  imageURL: null;
  testimonialText: string;
};
