"use client";
import ProductRecommendation from "@/app/ec/product/recommendations/productRecommendation";
import RecommendationsSlider from "@/app/ec/product/recommendations/recommendationsSlider";
import { useLocationDetails } from "@/app/hooks/useLocationDetails";
import { ProductHit } from "@/components/products-tile/product";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  RecommendationsSliceState,
  generateRecommendationsCacheKey,
  getRecommendations,
} from "@/store/recommendationsSlice";
import { getBestPrices } from "@/store/searchSlice";
import { AppState } from "@/store/store";
import { createSelector } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useInView } from "react-intersection-observer";

/**
 * This component fetches the data from the algolia based on the model name and
 * then pass that data to the RenderItems component to render the specific recommendations.
 *
 * Incase we don't receive any recommendations data from the provided model with the provided filters
 * and a fallback model is provided then it tries to render the fallback model with fallback props
 * else it tries to render nothing and returns null.
 */
const Recommendations = ({
  objectIDs = [],
  modelParams = {},
  model,
  fallbackModel,
  fallbackTitle,
  fallbackModelParams,
  isFallback = false,
  title,
  id,
  className = "",
  maxRecommendations = 18,
  orderBy,
  displayGrid,
}: RecommendationProps) => {
  const { ref, inView /* , entry */ } = useInView({
    rootMargin: "-200px 0px 0px 0px",
    triggerOnce: true,
  });
  const { zipCode } = useLocationDetails();
  const dispatch = useAppDispatch();

  const selectRecommendations = useMemo(() => {
    return createSelector(
      [
        (state: AppState) => state.recommendationsSlice.recommendations,
        (state: AppState, cacheKey: string) => cacheKey,
      ],
      (recommendations: RecommendationsSliceState["recommendations"], cacheKey: string) => recommendations[cacheKey],
    );
  }, []);
  const recommendationsCacheKey = useMemo(() => {
    const key = generateRecommendationsCacheKey(model, objectIDs, modelParams);
    return key;
  }, [model, objectIDs, modelParams]);

  const recommendations = useAppSelector((state) => selectRecommendations(state, recommendationsCacheKey));

  useEffect(() => {
    if (recommendations && recommendations.loadingState === "complete" && recommendations.hits.length > 0) {
      const mpIds = recommendations.hits.map((hit): number => hit.mp_id);
      dispatch(getBestPrices({ mpIds: mpIds, postalCode: zipCode }));
    }
  }, [recommendations, zipCode, dispatch]);

  const isLoading = useMemo(() => {
    if (!recommendations) {
      return true;
    }
    return recommendations.loadingState === "loading";
  }, [recommendations]);

  useEffect(() => {
    if (inView && !recommendations) {
      dispatch(getRecommendations({ model, objectIDs, maxRecommendations, ...modelParams }));
    }
  }, [dispatch, recommendations, inView, model, objectIDs, maxRecommendations, modelParams]);

  const factorIdChangeCallback = useCallback(() => {
    const factorIdDiv = document.getElementById("factorIdDiv");
    if (
      factorIdDiv &&
      recommendations &&
      recommendations.loadingState === "complete" &&
      recommendations.hits.length > 0
    ) {
      Cookies.set("buyBoxFactorIdExperiment", factorIdDiv.innerHTML, { expires: 1 / 24 });
      const mpIds = recommendations.hits.map((hit): number => hit.mp_id);
      dispatch(
        getBestPrices({
          mpIds: mpIds,
          postalCode: zipCode,
          additionalCacheKey: `factorId:${factorIdDiv.innerHTML}`,
        }),
      );
    }
  }, [dispatch, zipCode, recommendations]);

  useEffect(() => {
    window.addEventListener("factorIDChange", factorIdChangeCallback);
    const intervalFactorIdChecker = setInterval(() => {
      const factorIdDiv = document.getElementById("factorIdDiv");
      if (
        factorIdDiv &&
        recommendations &&
        recommendations.loadingState === "complete" &&
        recommendations.hits.length > 0
      ) {
        const validCookie = Cookies.get("buyBoxFactorIdExperiment");
        if (!validCookie) {
          const mpIds = recommendations.hits.map((hit): number => hit.mp_id);
          Cookies.set("buyBoxFactorIdExperiment", factorIdDiv.innerHTML, { expires: 1 / 24 });
          dispatch(
            getBestPrices({
              mpIds: mpIds,
              postalCode: zipCode,
              additionalCacheKey: `factorId:${factorIdDiv.innerHTML}`,
            }),
          );
        }
      }
    }, 1000 * 60);
    return () => {
      window.removeEventListener("factorIDChange", factorIdChangeCallback);
      clearInterval(intervalFactorIdChecker);
    };
  }, [dispatch, factorIdChangeCallback, zipCode, recommendations]);

  const formattedID = useMemo(() => {
    return id || `${model}-${title.replace(/\s+/g, "-").toLowerCase()}`;
  }, [id, model, title]);

  const sortedRecommendations: ProductHit[] = useMemo(() => {
    try {
      if (recommendations && recommendations.hits.length > 0 && orderBy) {
        if (Array.isArray(orderBy)) {
          return [...recommendations.hits].sort((a, b) => {
            if (!a || !b) {
              return 1;
            }
            if (orderBy.length > 1) {
              return a[orderBy[0]] == b[orderBy[0]]
                ? (b[orderBy[1]] || 0) - (a[orderBy[1]] || 0)
                : (b[orderBy[0]] || 0) - (a[orderBy[0]] || 0);
            } else {
              return a[orderBy[0]] == b[orderBy[0]] ? 0 : (b.orderBy[0] || 0) - (a.orderBy[0] || 0);
            }
          });
        } else if (typeof orderBy === "string") {
          return [...recommendations.hits].sort((a, b) => {
            if (a[orderBy] === b[orderBy]) {
              return 0;
            }
            return a[orderBy] < b[orderBy] ? 1 : -1;
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    return recommendations?.hits || [];
  }, [recommendations, orderBy]);

  if (isFallback === false && !isLoading && !recommendations?.hits.length) {
    if (!fallbackModel) {
      return null;
    }
    return (
      <Recommendations
        modelParams={fallbackModelParams || modelParams}
        model={fallbackModel}
        title={fallbackTitle || title}
        objectIDs={objectIDs}
        isFallback={true}
        id={id}
        orderBy={orderBy}
        maxRecommendations={maxRecommendations}
        displayGrid={displayGrid}
      />
    );
  }

  return (
    <div
      ref={ref}
      className={className}
      data-is-loading={!recommendations || recommendations.loadingState === "loading"}
      data-is-fallback={isFallback}
      data-in-view={inView}
    >
      <ErrorBoundary fallback={<></>}>
        {/*This is for the multi-factor buy box id experiment*/}
        <div id="factorIdDiv" style={{ display: "none" }}></div>{" "}
        {(sortedRecommendations.length > 0 || isLoading) && (
          <div>
            {title && (
              <div
                id={formattedID + "-title"}
                className={`product-recommendations-title h5 ${displayGrid ? "center-display" : ""} ${isLoading ? "loading-title" : ""}`}
              >
                {title}
              </div>
            )}

            <RecommendationsSlider
              key={formattedID + "-slider"}
              showLoadingRecommendations={isLoading}
              displayGrid={displayGrid}
            >
              {sortedRecommendations.map((recommendation) => {
                return (
                  <ProductRecommendation
                    key={recommendation.mp_id}
                    mpId={recommendation.mp_id}
                    title={recommendation.title}
                    brandName={recommendation.brand}
                    imageLink={recommendation.image_link}
                    detailLink={recommendation.link}
                    retailPrice={recommendation.retail_price}
                    bestPrice={recommendation.price}
                    numberOfRatings={recommendation.number_of_general_ratings}
                    rating={recommendation.general_rating}
                  />
                );
              })}
            </RecommendationsSlider>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Recommendations;

export type RecommendationModel = "related-products" | "bought-together" | "trending-items";

export type RecommendationProps = {
  title: string;
  fallbackTitle?: string;
  id?: string;
  className?: string;
  model: RecommendationModel;
  fallbackModel?: RecommendationModel;
  objectIDs?: string[];
  modelParams?: { [key: string]: any };
  fallbackModelParams?: { [key: string]: any };
  /**
   * The key with which to sort the product hits.
   * If an array is provided, it will attempt to sort
   * by the first element of the array and if they both match,
   * it will attempt the second.  It will disregard any other elements provided.
   */
  orderBy?: keyof ProductHit | Array<keyof ProductHit>;
  postalCode?: string;
  isFallback?: boolean;
  maxRecommendations?: number;
  displayGrid?: boolean;
};
