"use client";

import { useMemo } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useGetWeeklySpecialsQuery } from "@/store/baseApi";
import style from "./weeklySpecialsCountdown.module.scss";
import { Button } from "react-bootstrap";
import RecommendationsSlider from "@/app/ec/product/recommendations/recommendationsSlider";
import WeeklySpecialsProduct from "@/app/(homepage)/weekly-specials/weeklySpecialsProduct";

export default function WeeklySpecialsCountdown({ justMessage }: WeeklySpecialsCountdownProps) {
  const { data: weeklySpecials } = useGetWeeklySpecialsQuery();
  const weeklySpecialsEndTime = weeklySpecials?.endTime;
  return (
    <div className={style.container}>
      {weeklySpecialsEndTime && (
        <section className={style.containsRecommendations}>
          <div className={style.sectionHeader}>
            <div>
              <span className="d-block medium">
                Weekly Specials{" "}
                <span className="text-secondary">
                  <Countdown
                    date={weeklySpecialsEndTime}
                    renderer={(props) => {
                      return <WeeklySpecialsInteriorComponent {...props} justMessage={justMessage} />;
                    }}
                  />
                  <Button href="/weekly-specials" variant="primary" size="sm" className={`${style.btn} pr-3 pl-3`}>
                    See All &raquo;
                  </Button>
                </span>
              </span>
            </div>
          </div>
          <RecommendationsSlider showLoadingRecommendations={false} rows={6}>
            {weeklySpecials?.products.map((weeklySpecial, index) => {
              return <WeeklySpecialsProduct key={index} product={weeklySpecial} />;
            })}
          </RecommendationsSlider>
        </section>
      )}
    </div>
  );
}
export type WeeklySpecialsCountdownProps = {
  justMessage?: boolean;
};

function WeeklySpecialsInteriorComponent({
  days,
  hours,
  minutes,
  seconds,
  completed,
  justMessage,
}: WeeklySpecialsInteriorComponentProps) {
  /** This should be equal to "day" only when it is 1.  If it is 0, "days" is correct. */
  const dayOrDays = useMemo(() => (days == 1 ? "day" : "days"), [days]);
  const formattedHours = useMemo(() => hours.toString().padStart(2, "0"), [hours]);
  const formattedMinutes = useMemo(() => minutes.toString().padStart(2, "0"), [minutes]);
  const formattedSeconds = useMemo(() => seconds.toString().padStart(2, "0"), [seconds]);
  const shortMessage = useMemo(() => {
    return generateShortWeeklySpecials(days, hours, minutes, seconds);
  }, [days, hours, minutes, seconds]);

  if (completed === true) {
    return null;
  }

  if (justMessage) {
    return <>{shortMessage}</>;
  }
  return (
    <>
      <span className={`ml-1 text-secondary ${style.wsCountdownDesktopVersion}`}>
        ends in {days} {dayOrDays} {formattedHours}:{formattedMinutes}:{formattedSeconds}
      </span>
      <span className={`ml-1 text-secondary ${style.wsCountdownMobileVersion}`}>end in {shortMessage}</span>
    </>
  );
}

type WeeklySpecialsInteriorComponentProps = CountdownRenderProps & { justMessage?: boolean };

export const generateShortWeeklySpecials = (days: number, hours: number, minutes: number, seconds: number): string => {
  if (days > 0) {
    const dayOrDays = days == 1 ? "day" : "days";
    return `${days} ${dayOrDays}`;
  } else if (hours > 0) {
    const hourOrHours = hours == 1 ? "hour" : "hours";
    return `${hours} ${hourOrHours}`;
  } else if (minutes > 0) {
    const minuteOrMinutes = hours == 1 ? "minute" : "minutes";
    return `${minutes} ${minuteOrMinutes}`;
  } else if (seconds > 0) {
    const secondOrSeconds = hours == 1 ? "second" : "seconds";
    return `${seconds} ${secondOrSeconds}`;
  } else {
    return "";
  }
};
