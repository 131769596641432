"use client";

import CurrencyDisplay from "@/app/components/currencyDisplay";
import { useAppSelector } from "@/store/hooks";
import { makeGetBestPriceByMpIdSelector } from "@/store/searchSlice";
import fixURI from "@/utils/fixURI";
import Big from "big.js";
import Link from "next/link";
import { FunctionComponent, useMemo } from "react";
import { NumericFormat } from "react-number-format";
import ProductImage from "../productImage";

const ProductRecommendation: FunctionComponent<ProductRecommendationProps> = ({
  isLoadingRecommendation = false,
  title = "This is a loading title name that will not be visible to take up space.",
  mpId = -1,
  brandName = "",
  imageLink = "",
  detailLink = "",
  retailPrice = 0,
  bestPrice = 0,
  numberOfRatings = 0,
  rating = 0,
}) => {
  const selectBestPriceByMpId = useMemo(makeGetBestPriceByMpIdSelector, []);
  const productFromServer = useAppSelector((state) => selectBestPriceByMpId(state, mpId));

  const chosenBestPrice = productFromServer?.unitPrice || bestPrice;

  const savings = useMemo(() => {
    if (!chosenBestPrice) {
      return 0;
    }
    const bigRetailPrice = new Big(retailPrice);
    const bigBestPrice = new Big(chosenBestPrice);
    if (bigRetailPrice.lte(bigBestPrice)) {
      return 0;
    }
    const savings = bigRetailPrice.minus(bigBestPrice).div(bigRetailPrice).times(100);
    if (savings.lt(1)) {
      return 0;
    }
    return savings.toNumber();
  }, [retailPrice, chosenBestPrice]);
  const adjustedRetailPrice = useMemo(() => {
    try {
      if (!productFromServer) {
        if (new Big(chosenBestPrice).gte(retailPrice)) {
          return 0;
        } else {
          return retailPrice;
        }
      } else {
        if (!productFromServer.retailPrice) {
          return retailPrice;
        } else if (new Big(productFromServer.unitPrice).gte(productFromServer.retailPrice)) {
          return 0;
        } else {
          return productFromServer.retailPrice;
        }
      }
    } catch (error) {
      console.error(error);
      return retailPrice;
    }
  }, [chosenBestPrice, productFromServer, retailPrice]);
  const fixedDetailLink = useMemo(() => {
    if (!detailLink) {
      return "#";
    }
    return fixURI(detailLink);
  }, [detailLink]);
  const ratingsClass = useMemo(() => {
    return "recommendating-rating-" + parseInt(rating as any).toString() + "_" + (rating % 1 == 0 ? "0" : "5");
  }, [rating]);

  return (
    <div className="product-recommendation-container">
      <div className={`product-recommendation ${isLoadingRecommendation ? "loading-recommendation" : ""}`}>
        <Link href={fixedDetailLink} className="recommendation-image" prefetch={false}>
          {isLoadingRecommendation ? (
            <div className="no-image"></div>
          ) : (
            <ProductImage src={imageLink} alt={title} width={140} height={140} />
          )}
        </Link>
        <Link href={fixedDetailLink} className={"recommendation-brand sm text-dark"} prefetch={false}>
          {brandName}
        </Link>
        <Link href={fixedDetailLink} className="recommendation-title sm text-dark" prefetch={false}>
          {title}
        </Link>
        <Link href={fixedDetailLink} className="recommendation-price md" prefetch={false}>
          {chosenBestPrice > 0 && <CurrencyDisplay value={chosenBestPrice} />}
        </Link>
        <Link
          href={fixedDetailLink}
          className={`recommendation-rating ${ratingsClass} ${numberOfRatings === 0 ? "no-ratings" : ""}`}
          prefetch={false}
        >
          <div className={"recommendation-ratings-inner-track " + ratingsClass}></div>
          <div className="recommendation-ratings-count">{numberOfRatings}</div>
        </Link>
        {retailPrice > chosenBestPrice && savings !== 0 && (
          <Link href={fixedDetailLink} className="recommendation-savings" prefetch={false}>
            {adjustedRetailPrice && (
              <>
                <span className="strike">
                  <CurrencyDisplay value={adjustedRetailPrice} />
                </span>{" "}
              </>
            )}
            <span className="savings" data-raw-savings={savings}>
              <NumericFormat
                value={savings}
                displayType="text"
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
              />
              % <span>savings</span>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export type ProductRecommendationProps = {
  isLoadingRecommendation?: boolean;
  title?: string;
  mpId?: number;
  brandName?: string;
  imageLink?: string;
  detailLink?: string;
  retailPrice?: number;
  bestPrice?: number;
  numberOfRatings?: number;
  rating?: number;
  savings?: number;
};

export default ProductRecommendation;
