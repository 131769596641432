import { FunctionComponent } from "react";
import { NumericFormat } from "react-number-format";

import CurrencyDisplay from "@/app/components/currencyDisplay";
import fixImageURI from "@/app/ec/product/fixImageURI";
import ProductImage from "@/app/ec/product/productImage";

import style from "./weeklySpecialsProduct.oldStyle.module.scss";

const WeeklySpecialsProduct: FunctionComponent<WeeklySpecialsProductProps> = ({ product, ...rest }) => {
  const savings = ((product.RetailPrice - product.BestPrice) / product.RetailPrice) * 100;
  const detailLink = product.DetailLink.charAt(0) == "/" ? product.DetailLink : "/" + product.DetailLink;
  const ratingsClass = (() => {
    let rating = product.Rating;
    if (!rating)
      rating =
        product.NumberOfGeneralRatings === 0
          ? 0
          : Math.round((product.GeneralRatingSum / product.NumberOfGeneralRatings) * 2) / 2;
    return "recommendatingRating-" + parseInt(rating as any).toString() + "_" + (rating % 1 == 0 ? "0" : "5");
  })();
  return (
    <div className={style.weeklySpecialsProductContainer} key={"certona" + product.MpId}>
      <div className={style.weeklySpecialsProduct}>
        <a href={detailLink} className={style.weeklySpecialsProductImage}>
          <ProductImage src={fixImageURI(product.SmallPhotoPath as string)} alt={""} width={94} height={94} />
        </a>

        <a href={detailLink} className={`${style.weeklySpecialsProductBrand} sm text-dark`}>
          {product.BrandName}
        </a>
        <a href={detailLink} className={`${style.weeklySpecialsProductTitle} sm text-dark`}>
          {product.MpName}
        </a>
        <a href={detailLink} className={`${style.weeklySpecialsProductPrice} md`}>
          <CurrencyDisplay value={product.BestPrice} />
        </a>
        <a href={detailLink} className={`${style.weeklySpecialsProductRating} ${ratingsClass}`}>
          <div
            className={`${style.weeklySpecialsProductRatingsInnerTrack} ${style[ratingsClass] || ratingsClass}`}
          ></div>
          <div className={`${style.weeklySpecialsProductRatingsCount}`}>{product.NumberOfGeneralRatings}</div>
        </a>
        <a href={detailLink} className={style.weeklySpecialsProductSavings}>
          <span className={style.strike}>
            <CurrencyDisplay value={product.RetailPrice} />
          </span>{" "}
          <span className={style.savings}></span>
        </a>
        <div className={style.percentageOff}>
          <NumericFormat
            value={savings}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            fixedDecimalScale={true}
          />
          % <span>off</span>
        </div>
      </div>
    </div>
  );
};

type WeeklySpecialsProductProps = {
  product: Products.Recommendations.ProductRecommendation;
};

export default WeeklySpecialsProduct;
