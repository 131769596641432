"use client";

import ProductRecommendation from "@/app/ec/product/recommendations/productRecommendation";
import React, { FunctionComponent, ReactNode, useMemo, useRef, useState } from "react";
import { Grid, Navigation, Pagination, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import style from "./recommendationsSlider.module.scss";
import { Swiper as SwiperInterface } from "swiper";

const RecommendationsSlider: FunctionComponent<RecommendationsSlidersProps> = ({
  children,
  className = "",
  displayGrid,
  showLoadingRecommendations,
  rows = 3,
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | null>(null);

  const loadingRecommendations = useMemo(() => {
    const max = 6;
    const loadingRecommendations = [];
    for (let index = 0; index < max; index++) {
      loadingRecommendations.push("");
    }
    return loadingRecommendations;
  }, []);

  const recommendationsDisplay = (
    <>
      {showLoadingRecommendations &&
        loadingRecommendations.map((value, index) => {
          return (
            <SwiperSlide key={`loading-slide-${index}`}>
              <ProductRecommendation key={index} isLoadingRecommendation={true} />
            </SwiperSlide>
          );
        })}
    </>
  );

  const childrenDisplay = (
    <>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return (
            <SwiperSlide key={child.key ? child.key : index} virtualIndex={index}>
              {child}
            </SwiperSlide>
          );
        }
      })}
    </>
  );

  return (
    <>
      <div className={`d-none d-md-flex align-items-center ${className}`}>
        {displayGrid ? (
          <Swiper
            grid={{
              rows: 2,
              fill: "row",
            }}
            modules={[Grid]}
            navigation={false}
            className={`flex-grow-1 ${style.recommendationsSlider} ${style.maxGridWith}`}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              750: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 6,
              },
            }}
          >
            {recommendationsDisplay}
            {childrenDisplay}
          </Swiper>
        ) : (
          <>
            <div className={`swiper-arrow ${style.swiperArrow} ${style.prev}`} ref={navigationPrevRef}></div>
            <Swiper
              className={`flex-grow-1 ${style.recommendationsSlider}`}
              modules={[Navigation, Virtual]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              breakpointsBase="container"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                250: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                500: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                750: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                1000: {
                  slidesPerView: 5,
                  slidesPerGroup: 5,
                },
                1250: {
                  slidesPerView: 6,
                  slidesPerGroup: 6,
                },
              }}
              simulateTouch={false}
              direction="horizontal"
              virtual
            >
              {recommendationsDisplay}
              {childrenDisplay}
            </Swiper>
            <div className={`swiper-arrow ${style.swiperArrow} ${style.next}`} ref={navigationNextRef}></div>
          </>
        )}
      </div>
      <div className={`d-block d-md-none ${className}`}>
        <Swiper
          className={`${style.recommendationsSlider} ${style.mobile}`}
          autoHeight={true}
          modules={[Pagination, Grid]}
          pagination={{
            el: paginationRef.current,
            type: "bullets",
            clickable: true,
          }}
          grid={{
            rows,
            fill: "row",
          }}
          simulateTouch={false}
          direction="horizontal"
        >
          {recommendationsDisplay}
          {childrenDisplay}
          <div className={style.pagination} ref={paginationRef}></div>
        </Swiper>
      </div>
    </>
  );
};

interface RecommendationsSlidersProps {
  children: ReactNode;
  className?: string;
  showLoadingRecommendations: boolean;
  displayGrid?: boolean;
  rows?: number;
}

export default RecommendationsSlider;
