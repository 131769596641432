import { UserState } from "@/store/userApi";
import computeSubtotalOfAnalyticsProduct from "@/utils/analytics/computeSubtotalOfAnalyticsProduct";
import {
  convertIncomingAnalyticsProductToAnalyticsReportProductV2,
  convertShoppingCartProductToAnalyticsReportProductV2,
} from "@/utils/analytics/convertToAnalyticsReportProductV2";
import getProductDetailsForReport from "@/utils/analytics/getProductDetailsForReport";
import Big from "big.js";
import { AnalyticsSource, AnalyticsUserData, DispatchAnalyticsEventProps } from "../app/hooks/useAnalyticsManager";

export const dispatchAnalyticsEvent = ({ eventName, ...rest }: DispatchAnalyticsEventProps) => {
  try {
    if (!window.dataLayer) window.dataLayer = [];
    const dataLayerEvent = {
      event: eventName,
      ...rest,
    };
    console.log("GTM | Reporting the following event (" + eventName + "): ", dataLayerEvent);
    window.dataLayer.push(dataLayerEvent);
  } catch (error) {
    console.error("Could not report to GTM. There is likely an adblocker.  Reason: ", error);
    if (rest.eventCallback) {
      rest.eventCallback();
    }
  }
};

/**
 * This dispatches a null eCommerce object to the data layer to clear out the object.  This was recommended by
 * a 3rd party doing hte UA/GA3 > GA4 conversion
 */
export function dispatchNullECommerceEvent() {
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push({ ecommerce: null });
}

/**
 *
 * @param products products add/removed from shopping cart
 * @param source from where we are sending this event
 */
export const modifyCartAnalyticsEvent = async (
  products: Array<IncomingAnalyticsProduct>,
  userState: UserState | undefined,
  source: AnalyticsSource,
  incrementQuantity = false,
  queryId = "",
  shoppingCart: ShoppingCartState | undefined,
) => {
  const productMap: AnalyticsProductMap = {
    added: [],
    removed: [],
  };
  products.forEach((product) => {
    const convertedProduct = convertIncomingAnalyticsProduct(product);
    if (product.quantity == 0) {
      productMap.removed.push(convertedProduct);
    } else {
      productMap.added.push(convertedProduct);
    }
  });
  if (productMap.added.length > 0) {
    dispatchNullECommerceEvent();
    dispatchAnalyticsEvent({
      eventName: "addToCart",
      source: source,
      ecommerce: {
        add: {
          products: productMap.added,
        },
      },
      queryID: queryId,
      userData: convertUserStateToUserDataForAnalytics(userState),
    });
  }
  if (productMap.removed.length > 0) {
    dispatchNullECommerceEvent();
    dispatchAnalyticsEvent({
      eventName: "removeFromCart",
      source: source,
      ecommerce: {
        remove: {
          products: productMap.removed,
        },
      },
      queryID: queryId,
    });
  }

  // The following is for GA4
  const productsWithAdditionalDetails: AnalyticsReportProductV2[] = await getProductDetailsForReport(
    convertIncomingAnalyticsProductToAnalyticsReportProductV2(products, incrementQuantity),
  );
  const shoppingCartProductsWithAdditionalDetails: AnalyticsReportProductV2[] = await getProductDetailsForReport(
    convertShoppingCartProductToAnalyticsReportProductV2(shoppingCart),
  );

  const productsMapV2: AnalyticsProductMapV2 = {
    added: [],
    removed: [],
  };

  productsWithAdditionalDetails.forEach((p) => {
    if (p.quantity > 0) {
      productsMapV2.added.push(p);
    } else if (p.quantity < 0) {
      productsMapV2.removed.push({
        ...p,
        quantity: Math.abs(p.quantity),
      });
    }
    // else do nothing
  });

  if (productsMapV2.added.length > 0) {
    // Here we'll make sure the products we've added are included as part
    // of the shopping cart while de-duping them.
    const cartWithAddedProducts = [...shoppingCartProductsWithAdditionalDetails];
    productsWithAdditionalDetails.forEach((product) => {
      if (!cartWithAddedProducts.find((p) => p.item_id === product.item_id)) {
        cartWithAddedProducts.push(product);
      }
    });
    dispatchNullECommerceEvent();
    dispatchAnalyticsEvent({
      source: source,
      eventName: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: computeSubtotalOfAnalyticsProduct(productsMapV2.added).toNumber(),
        items: productsMapV2.added,
      },
      userData: convertUserStateToUserDataForAnalytics(userState),
      queryID: queryId,
      shoppingCart: {
        // We need to recalculate subtotal here because the cart isn't up to date at this point.
        subTotal: cartWithAddedProducts
          .reduce((accumulator, cartProduct) => {
            if (!cartProduct.price) {
              return accumulator; // This shoudl not occur, for typescript.
            }
            return accumulator.plus(new Big(cartProduct.price).times(cartProduct.quantity));
          }, new Big(0))
          .toNumber(),
        products: cartWithAddedProducts,
      },
    });
  }
  if (productsMapV2.removed.length > 0) {
    // Here we'll make sure the products we've added are removed from
    // the shopping cart while de-duping them.
    const cartWithoutRemovedProducts = [...shoppingCartProductsWithAdditionalDetails].filter((product) => {
      if (productsWithAdditionalDetails.some((pwad) => pwad.item_id === product.item_id)) {
        return false;
      }
      return true;
    });
    dispatchNullECommerceEvent();
    dispatchAnalyticsEvent({
      source: source,
      eventName: "remove_from_cart",
      ecommerce: {
        currency: "USD",
        // value: computeSubtotalOfAnalyticsProduct(productsMapV2.removed).toNumber(),
        items: productsMapV2.removed,
      },
      queryID: queryId,
      shoppingCart: shoppingCart && {
        // We need to recalculate subtotal here because the cart isn't up to date at this point.
        subTotal: cartWithoutRemovedProducts
          .reduce((accumulator, cartProduct) => {
            if (!cartProduct.price) {
              return accumulator; // This shoudl not occur, for typescript.
            }
            return accumulator.plus(new Big(cartProduct.price).times(cartProduct.quantity));
          }, new Big(0))
          .toNumber(),
        products: cartWithoutRemovedProducts,
      },
    });
  }
};

export const convertIncomingAnalyticsProduct = (incomingProduct: IncomingAnalyticsProduct): AnalyticsReportProduct => {
  const reportProduct: AnalyticsReportProduct = {
    id: incomingProduct.mpId,
    quantity: incomingProduct.quantity,
  };
  if ("productTitle" in incomingProduct) reportProduct["name"] = incomingProduct.productTitle;
  if ("unitPrice" in incomingProduct) reportProduct["price"] = incomingProduct.unitPrice;
  if ("brandName" in incomingProduct) reportProduct["brand"] = incomingProduct.brandName;
  if ("categoryName" in incomingProduct) reportProduct["category"] = incomingProduct.categoryName;
  if ("isOnPromotion" in incomingProduct) reportProduct["coupon"] = "Weekly Specials";
  if ("productTitle" in incomingProduct) reportProduct["variant"] = incomingProduct.productTitle;
  return reportProduct;
};

/**
 * Returns the user state confverted to AnalyticsUserData's type.  Returns undefined if the user is a guest.
 * @param {UserState} userState The redux store user state object.
 * @returns
 */
export const convertUserStateToUserDataForAnalytics = (
  userState: UserState | undefined,
): AnalyticsUserData | undefined => {
  if (!userState || userState.IsGuestUser === true) return undefined;
  return {
    userId: userState.UserId,
    userEmailAddress: userState.EmailAddress,
    firstName: userState.FirstName,
    lastName: userState.LastName,
    totalOrders: userState.TotalOrders ? userState.TotalOrders : 0,
    isDentalCustomer: userState.IsProfessionalOffice != undefined ? userState.IsProfessionalOffice : false,
    hash: userState.UserIdHash ? userState.UserIdHash : "",
  };
};

export type IncomingAnalyticsProduct = Partial<Magazine.Products.VendorProduct> &
  Pick<Magazine.Products.VendorProduct, "mpId" | "quantity"> & {
    categoryList?: string[];
    originalQuantity: number;
  };
export type AnalyticsProductMap = {
  added: AnalyticsReportProduct[];
  removed: AnalyticsReportProduct[];
};

export type AnalyticsReportProduct = {
  id: number;
  name?: string;
  quantity?: number;
  price?: number;
  brand?: string;
  category?: string;
  categories?: string[];
  variant?: string;
  coupon?: string;
  image_url?: string;
  product_detail_page_url?: string;
  retail_price?: string | number;
};

/**
 * This dispatches a null eCommerce object to the data layer to clear out the object.  This was recommended by
 * a 3rd party doing hte UA/GA3 > GA4 conversion
 */
export function dispatchNullEcommerceEvent() {
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push({
    ecommerce: null,
    event: "",
  });
}

export type AnalyticsReportProductV2 = {
  item_id: string;
  item_name?: string;
  price?: number;
  quantity: number;
  item_brand?: string;
  /** The top level category */
  item_category?: string;
  /** The first sub category down from the top */
  item_category2?: string;
  /** The second sub category down from the top */
  item_category3?: string;
  /** The third sub category down from the top */
  item_category4?: string;
  /** The forth sub category down from the top */
  item_category5?: string;
  /** Not used by google, this is used to determine what category the product is most
   * accurately in. For a category tree of "Dental Supplies > Preventives > Pit and fissure sealants"
   * this would be in "Pit and fissure sealants"
   */
  item_specific_category?: string;
  /** Not used by google, this gives the absolute url of the product for Klaviyo. */
  item_absolute_url?: string;
  /** Not used by google, this gives the absolute url of the product's image for Klaviyo. */
  item_absolute_image?: string;
  /** Not used by google, this is the total of (price * quantity) */
  item_subtotal?: number;
  /** Not used by google, this is the retail price */
  item_retail_price?: number;
};

export type AdditionalAnalyticsProductInfo = {
  /** Vendor name that won the buy box, ie "Tradent" */
  buybox_winning_vendor?: string;
  /** Does the vendor that won the buy box have an authorized badge? "yes" is when there is a badge, "no" means there is no badge*/
  buybox_winning_vendor_isAuthorized?: "yes" | "no";
  /** This is the number of business days until delivery from the buy box winner */
  buybox_delivery_days?: number;
  /** Number of handling time business days from the buy box winner */
  buybox_handling_time?: number;
  /** Is the buy box winner the lowest total price compared to all other vendors? Set "yes" or "no" */
  buybox_isLowestPrice?: "yes" | "no";
  /** Does the buy box winner have the lowest amount of business delivery days compared to all other vendors on the page? Set "yes" or "no  */
  buybox_isLowestDeliveryDays?: "yes" | "no";
  /** Does the buybox winning vendor show "Stocked", "Long Handling Time" or "Backordered"? */
  buybox_fulfillment_message?: "Stocked" | "Long Handling Time" | "Backordered" | null;
  /** The total number of vendors for the product */
  all_vendors_count?: number;
  /** The number of vendors that are Authorized Distributors */
  all_vendors_isAuthorized_count?: number;
  /** The lowest number of delivery days among the vendors */
  all_vendors_lowest_delivery_days?: number;
  /** The lowest price for the product among all vendors */
  all_vendors_lowest_price?: number;
  /** The state the user is ordering from. Defaults to North Carolina */
  user_shipping_state?: string;
  /** The subregion the user is ordering from. Defaults to Central North Carolina */
  user_shipping_subRegion?: string;
};

export type AnalyticsProductMapV2 = {
  added: AnalyticsReportProductV2[];
  removed: AnalyticsReportProductV2[];
};
