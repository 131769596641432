"use client";

import QuickSearchSwiper from "@/app/(homepage)/dashboard/quickSearchSwiper";
import AlgoliaSearchBarClient from "@/app/layouts/standard/header/algoliaSearchBarClient";
import style from "./searchComponent.module.scss";

export default function SearchComponent({
  topCategories,
  searchData,
}: {
  topCategories: any;
  searchData: SearchData | null;
}) {
  return (
    <div id="dashboard" className={style.searchContainer}>
      <div id="homePageSearchDashboard" className={style.homePageSearchDashboard}>
        <AlgoliaSearchBarClient topCategories={topCategories} />
      </div>
      <QuickSearchSwiper searchData={searchData} />
    </div>
  );
}
