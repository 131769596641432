export const getBannerLink = (winner: Winner | undefined): string | undefined => {
  if (winner) {
    if (winner.type === "product") {
      const vendorParam = "?tsid=" + winner.vendorId;
      return "/ec/-d-" + winner.id + vendorParam;
    }
    if (winner.type === "url") {
      let givenUrl = winner.id;
      if (givenUrl.toLowerCase().startsWith("https://") || givenUrl.toLowerCase().startsWith("http://")) {
        return winner.id;
      } else {
        return "https://" + winner.id;
      }
    }
  }
  return undefined;
};
