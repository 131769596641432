import Recommendations, { RecommendationProps } from "@/app/ec/product/recommendations/recommendations";
import { useGetLastPurchasedProductsQuery } from "@/store/userApi";

const RecommendedForYou = (props: Omit<RecommendationProps, "model">) => {
  const { data: objectIDs, isLoading: lastPurchasedItemsIsLoading } = useGetLastPurchasedProductsQuery();

  if (lastPurchasedItemsIsLoading) return null;

  return <Recommendations objectIDs={objectIDs} {...props} model="related-products" />;
};

export default RecommendedForYou;
