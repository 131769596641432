import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/dashboard/customerHistoryBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/dashboard/firstCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/dashboard/ordersCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/dashboard/searchComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/dashboard/topsortBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/highest-quality-brands/highestQualityBrands.oldStyle.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/recently-viewed/homePageRecentlyViewedItems.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/homepage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/recommended-for-you/homePageProductRecommendations.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/specialities.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/dashboard/dashboardCard.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/dashboard/dashboard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/testimonials/testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/top-categories/topCategories.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(homepage)/value-proposition/valueProposition.oldStyle.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(homepage)/weekly-specials/weeklySpecialsCountdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentlyViewedItems"] */ "/app/src/components/recentlyViewedItems/recentlyViewedItems.tsx");
