"use client";

import ProductRecommendation from "@/app/ec/product/recommendations/productRecommendation";
import RecommendationsSlider from "@/app/ec/product/recommendations/recommendationsSlider";
import { useGetRecentlyViewedItemsQuery } from "@/store/recentlyViewedApi";
import { getLocalStorageItem } from "@/utils/storageUtil";
import { useMemo } from "react";
import { useInView } from "react-intersection-observer";

export const RecentlyViewedItems = ({ isHomePage }: RecentlyViewedItemsProps) => {
  const { ref, inView } = useInView({
    rootMargin: "-200px 0px 0px 0px",
    triggerOnce: true,
  });

  const mpIds = useMemo(() => {
    if (inView) {
      return getLocalStorageItem("recentlyViewedItemId", []);
    } else {
      return [];
    }
  }, [inView]);

  const { data, isLoading, isError } = useGetRecentlyViewedItemsQuery({ mpIds });

  const showRecentlyViewed = useMemo(() => {
    return !isError && !isLoading && data && data.length > 0 ? true : false;
  }, [data, isLoading, isError]);

  return (
    <div ref={ref}>
      <div className={showRecentlyViewed ? "d-block" : "d-none"}>
        <div
          id="recently-viewed"
          className={`product-recommendations-title h5 ${isHomePage ? "recently-viewed-title" : ""}`}
        >
          Recently Viewed
        </div>
        <RecommendationsSlider showLoadingRecommendations={false}>
          {data?.map((product) => {
            return (
              <ProductRecommendation
                key={product.mpId}
                mpId={product.mpId}
                title={product.title}
                brandName={product.brand}
                imageLink={product.imageLink}
                detailLink={product.link}
                retailPrice={product.bestPrice?.retailPrice}
                bestPrice={product.bestPrice?.unitPrice}
                numberOfRatings={product.numberOfGeneralRatings || 0}
                rating={product.generalRating || 0}
              />
            );
          })}
        </RecommendationsSlider>
        {/* )} */}
      </div>
    </div>
  );
};

export interface RecentlyViewedItemsProps {
  isHomePage?: boolean;
}
