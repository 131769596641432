import { RecommendationModel } from "@/app/ec/product/recommendations/recommendations";
import { ProductHit } from "@/components/products-tile/product";
import { defaultIndex, recommendClient } from "@/configs/constants";
import { isRootState } from "@/declarations/assertions/isRootState";
import type { AppState } from "@/store/store";
import { RecommendationsQuery } from "@algolia/recommend";
import { ActionReducerMapBuilder, GetThunkAPI, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { Hit } from "instantsearch.js";

const initialState: RecommendationsSliceState = {
  recommendations: {},
};

export type RecommendationsSliceState = {
  recommendations: { [key: string]: RecommendationsData | undefined };
};

export type RecommendationsData = {
  loadingState: "complete" | "loading" | "failed";
  hits: Hit<ProductHit>[];
};

export const recommendationsSlice = createSlice({
  name: "recommendationsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<RecommendationsSliceState>) => {
    builder.addCase(getRecommendations.fulfilled, (state: RecommendationsSliceState, action) => {
      if (action.payload) {
        const { cacheKey, hits } = action.payload;
        state.recommendations[cacheKey] = {
          loadingState: "complete",
          hits: hits,
        };
      }
    });
    builder.addCase(getRecommendations.pending, (state: RecommendationsSliceState, action) => {
      const { model, objectIDs = [], ...modelParams } = action.meta.arg;
      const cacheKey = generateRecommendationsCacheKey(model, objectIDs, modelParams);
      state.recommendations[cacheKey] = {
        loadingState: "loading",
        hits: [],
      };
    });
    builder.addCase(getRecommendations.rejected, (state: RecommendationsSliceState, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We're extracting maxRecommendations here on purpose even though we don't use it to keep it from the cache key.
      const { model, objectIDs = [], maxRecommendations, ...modelParams } = action.meta.arg;
      const cacheKey = generateRecommendationsCacheKey(model, objectIDs, modelParams);
      if (action.meta.aborted) {
        console.log("getRecommendations was aborted: ", action);
      } else {
        console.error("getRecommendations was rejected: ", action);
      }
      state.recommendations[cacheKey] = {
        loadingState: "failed",
        hits: [],
      };
    });
  },
});

export interface GetRecommendationsProps extends Omit<RecommendationsQuery, "model" | "objectID" | "indexName"> {
  objectIDs?: string[];
  model: RecommendationModel;
  maxRecommendations?: number;
  // sliderInView: boolean;
}

export const getRecommendations = createAsyncThunk(
  "recommendations/getRecommendations",
  async (
    { model, objectIDs = [], maxRecommendations = 18, ...modelParams }: GetRecommendationsProps,
    thunkApi: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const cacheKey = generateRecommendationsCacheKey(model, objectIDs, modelParams);
    try {
      const payload =
        model === "trending-items"
          ? [
              {
                indexName: defaultIndex,
                maxRecommendations,
                ...modelParams,
                queryParameters: modelParams.queryParameters && {
                  ...modelParams.queryParameters,
                  distinct: 1,
                },
              },
            ]
          : objectIDs.map((objectID) => ({
              indexName: defaultIndex,
              objectID: objectID,
              maxRecommendations,
              ...modelParams,
              queryParameters: modelParams.queryParameters && {
                ...modelParams.queryParameters,
                distinct: 1,
              },
            }));
      const recommendationMethods = {
        "trending-items": recommendClient.getTrendingItems,
        "related-products": recommendClient.getRelatedProducts,
        "bought-together": recommendClient.getFrequentlyBoughtTogether,
      };

      const method = recommendationMethods[model];

      if (payload.length === 0 || !method) {
        return thunkApi.abort("getRecommendations Thunk | No payload or method found.");
      }

      const data = await method<ProductHit>(payload as never);
      // This compresses any additional hits in the results array into a single array, though there should only ever be 1.
      const hits = data.results.flatMap((result) => result.hits || []);
      return {
        cacheKey,
        hits,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
  {
    condition: ({ model, objectIDs = [], ...modelParams }, { getState }) => {
      const state = getState();
      if (isRootState(state)) {
        const cacheKey = generateRecommendationsCacheKey(model, objectIDs, modelParams);
        const { recommendations } = state.recommendationsSlice;
        if (recommendations[cacheKey]) {
          console.log("getRecommendations Thunk | Recommendations already requested, aborting..");
          return false;
        }
      }
      return true;
    },
  },
);

/**
 * Generates a cache key for recommendations based on the model, objectIDs, and modelParams.
 *
 * This key is used access the recommendaitons cache in the store.
 * @param model
 * @param objectIDs
 * @param modelParams
 * @returns
 */
export function generateRecommendationsCacheKey(
  model: RecommendationModel,
  objectIDs: string[] = [],
  modelParams: Partial<GetRecommendationsProps>,
) {
  const cachableObjectIDs = [...objectIDs];
  cachableObjectIDs.sort(); // Sorting to encourage cache hits.
  return JSON.stringify({ model, objectIDs: cachableObjectIDs, ...modelParams });
}

export const makeGetRecommendationsSelector = () => {
  const selectRecommendations = createSelector(
    [(state: AppState) => state.recommendationsSlice.recommendations, (state: AppState, cacheKey: string) => cacheKey],
    (recommendations: RecommendationsSliceState["recommendations"], cacheKey: string) => recommendations[cacheKey],
  );
  return selectRecommendations;
};
